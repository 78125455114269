<template>
  <div
    class="flex flex-col rounded-md bg-white mb-9 mobile:flex-wrap mobile:items-center mobile:w-full"
  >
    <add-subscription
      v-if="pageLoaded"
      :subscription-tiers="subscriptionTiers"
      :subscription-extras="subscriptionExtras"
      :subscription-types="subscriptionTypes"
      :company-uuid="$store.state.companyData.activeCompany.uuid"
    >
    </add-subscription>
  </div>
</template>

<script>
import AddSubscription from "@/components/company/subscription/AddSubscription";
import RequestHandler from "@/assets/mixins/RequestHandler";

const subscriptionTypes = [
  {
    id: 2,
    name: "Kuumakse",
    information_text: "Paketi kasutus aastaks, maksed iga kuu",
    price_multiplier: 1,
    type: "yearly",
    price: 20
  },
  {
    id: 1,
    name: "Ühekordne makse",
    information_text: "Paketi kasutus aastaks, üks makse kogu perioodi eest",
    price_multiplier: 12,
    type: "yearly",
    price: 20
  },
  {
    id: 9,
    name: "Kuumakse",
    information_text: "Paketi kasutus kolmeks kuud, maksed iga kuu",
    price_multiplier: 1,
    type: "monthly",
    price: 25
  },
  {
    id: 8,
    name: "Ühekordne makse",
    information_text:
      "Paketi kasutus kolmeks kuuks, ühekordne makse kogu perioodi eest",
    price_multiplier: 3,
    type: "monthly",
    price: 25
  }
];

export default {
  components: {
    AddSubscription
  },
  mixins: [RequestHandler],
  data() {
    return {
      tiersLoaded: false,
      extrasLoaded: false,
      subscriptionTiers: [],
      subscriptionTypes: [],
      subscriptionExtras: []
    };
  },
  mounted() {
    this.subscriptionTypes = subscriptionTypes;
    this.retrieveSubscriptionTiers();
    this.retrieveSubscriptionExtras();
  },
  methods: {
    retrieveSubscriptionTiers() {
      this.apiRequest("subscription/tiers/", "get", true).then(res => {
        this.subscriptionTiers = res.data;
        this.tiersLoaded = true;
      });
    },
    retrieveSubscriptionExtras() {
      this.apiRequest("subscription/extras/", "get", true).then(res => {
        this.subscriptionExtras = res.data;
        this.extrasLoaded = true;
      });
    }
  },
  computed: {
    pageLoaded() {
      if (this.tiersLoaded && this.extrasLoaded) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
