<template>
  <div class="flex flex-col w-9/12 mx-auto medium:w-11/12 mobile:w-full">
    <div class="flex flex-col mb-8">
      <h3 class="mb-2">Valitud pakett</h3>
      <div class="font-medium">Gewodo {{ selectedTier.name }}</div>
      <div>
        <span>{{
          selectedType ? selectedType.name : "Paketi tüüp on valimata"
        }}</span>
      </div>
    </div>
    <div class="flex flex-col mb-12">
      <div class="flex flex-col" v-if="selectedTier">
        <div class="flex flex-col w-full">
          <div class="w-full flex flex-row mobile:hidden text-sm">
            <div class="w-1/5 items-center justify-start">
              <h4>Valitud pakett</h4>
            </div>
            <div class="w-1/5 flex justify-start">
              <h4>Kuutasu</h4>
            </div>
            <div class="w-1/5 flex justify-start">
              <h4>Kuude arv</h4>
            </div>
            <div class="w-1/5 flex justify-start">
              <h4>Ühikute arv</h4>
            </div>
            <div class="w-1/5 flex justify-start">
              <h4>Kogusumma</h4>
            </div>
          </div>
          <div
            class="py-2 rounded-xl flex flex-row w-full mobile:items-center mobile:flex-col"
          >
            <div
              class="w-1/5 mobile:w-full justify-start mobile:justify-between flex"
            >
              <span class="mr-2 mobile:flex hidden">Valitud pakett:</span>
              <span class="font-medium">{{ selectedTier.name }}</span>
            </div>
            <div
              class="w-1/5 mobile:w-full justify-start mobile:justify-between flex"
            >
              <span class="mr-2 mobile:flex hidden">Kuutasu:</span>
              <span>
                {{ unitPrice }}{{ $store.state.userData.user.currency }}
              </span>
            </div>
            <div
              class="w-1/5 mobile:w-full justify-start mobile:justify-between flex"
            >
              <span class="mr-2 mobile:flex hidden">Kuude arv:</span>
              <span>
                {{ selectedType ? selectedType.price_multiplier : "-" }}
              </span>
            </div>
            <div
              class="w-1/5 mobile:w-full justify-start mobile:justify-between flex"
            >
              <span class="mr-2 mobile:flex hidden">Ühikute arv:</span>
              <span>1</span>
            </div>
            <div
              class="w-1/5 mobile:w-full justify-start mobile:justify-between flex"
            >
              <span class="mr-2 mobile:flex hidden">Kogusumma:</span>
              <span>
                <span v-if="promocode.valid" class="strikethrough"
                  >{{ calculateTotalPriceNoDiscount()
                  }}{{ $store.state.userData.user.currency }}</span
                >
                {{
                  selectedType
                    ? `${calculateTotalPrice()} ${
                        $store.state.userData.user.currency
                      }`
                    : `-${$store.state.userData.user.currency}`
                }}
              </span>
            </div>
          </div>
          <div class="flex bg-offwhite h-1 rounded-full"></div>
        </div>
      </div>
      <div v-if="selectedTier === null" class="w-full px-3">
        <div class="p-3 bg-danger w-full rounded">
          Pakett on valimata
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-row mobile:items-center mobile:flex-col">
        <div class="flex w-1/2 mobile:w-full flex-col">
          <div class="w-1/2 flex flex-col">
            <span class="font-medium">Promokood</span>
            <span v-if="promocode.valid" class="mb-2">
              Allahindluse kood edudalt lisatud!
            </span>
            <span v-if="promocode.valid">
              Allahindlus {{ promocode.percentage * 100 }}%
            </span>
            <input
              v-model="promoCodeInput"
              class="w-full mb-2"
              v-if="!promocode.valid"
              :class="promocode.invalid ? 'shadow-dangeroutline' : ''"
            />
            <button
              class="w-2/3 new-button-green"
              @click="verifyPromoCode"
              v-if="!promocode.valid"
            >
              <span class="typcn typcn-plus icon"></span>
              <span class="label">Lisa</span>
            </button>
          </div>
        </div>
        <div class="flex w-1/2 flex-col mobile:w-full">
          <div
            class="flex justify-end mobile:justify-between mobile:w-full mb-4"
          >
            <div
              class="flex justify-end items-start mr-2 mobile:mr-0 mobile:justify-center mobile:w-1/2"
            >
              <h4>Kogusumma</h4>
            </div>
            <div class="flex justify-end w-1/5 mobile:w-1/2">
              <div class="w-full flex justify-center">
                <h4>
                  {{
                    `${calculateTotalPrice()}${
                      $store.state.userData.user.currency
                    }`
                  }}
                </h4>
              </div>
            </div>
          </div>
          <div class="flex justify-end mobile:justify-between mb-4">
            <div
              class="flex justify-end items-start mr-2 mobile:mr-0 mobile:justify-center mobile:w-1/2"
            >
              <h4>Käibemaks</h4>
            </div>
            <div class="flex justify-end w-1/5 mobile:w-1/2">
              <div class="w-full flex justify-center">
                <h4>
                  {{
                    `${calculateTax()}${$store.state.userData.user.currency}`
                  }}
                </h4>
              </div>
            </div>
          </div>
          <div
            class="flex h-1 bg-offwhite rounded-xl w-2/5 self-end mb-4"
          ></div>
          <div class="flex justify-end mobile:justify-between">
            <div
              class="flex justify-end items-start mr-2 mobile:mr-0 mobile:justify-center mobile:w-1/2"
            >
              <h4>Summa+km</h4>
            </div>
            <div class="flex w-1/5 mobile:w-1/2 justify-center">
              <div class="rounded-xl flex justify-center">
                <h4>
                  {{
                    `${calculateSum()}${$store.state.userData.user.currency}`
                  }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedType: Object,
    selectedTier: Object,
    promocode: Object
  },
  data() {
    return {
      promoCodeInput: "",
      promoCodeApplied: null
    };
  },
  methods: {
    calculateTotalPrice() {
      let totalPrice = 0.0;
      if (this.selectedTier && this.selectedType) {
        if (this.selectedType.type === "yearly") {
          totalPrice += parseFloat(this.selectedTier.yearly_price);
        } else {
          totalPrice += parseFloat(this.selectedTier.price);
        }

        totalPrice *= parseFloat(this.selectedType.price_multiplier);
      }
      if (this.promocode.code !== "") {
        totalPrice =
          totalPrice - totalPrice * parseFloat(this.promocode.percentage);
      }
      return totalPrice.toFixed(2);
    },
    calculateTotalPriceNoDiscount() {
      let totalPrice = 0.0;
      if (this.selectedTier && this.selectedType) {
        if (this.selectedType.type === "yearly") {
          totalPrice += parseFloat(this.selectedTier.yearly_price);
        } else {
          totalPrice += parseFloat(this.selectedTier.price);
        }

        totalPrice *= parseFloat(this.selectedType.price_multiplier);
      }

      return totalPrice.toFixed(2);
    },
    calculateSum() {
      const totalPrice = this.calculateTotalPrice();

      let vat = totalPrice * 0.2;
      let sum = parseFloat(totalPrice) + parseFloat(vat);
      return sum.toFixed(2);
    },
    verifyPromoCode() {
      this.$emit("verifyPromoCode", this.promoCodeInput);
    },
    calculateTax() {
      return Number(this.calculateTotalPrice() * 0.2).toFixed(2);
    }
  },
  computed: {
    unitPrice() {
      if (this.selectedType.type === "yearly") {
        return this.selectedTier.yearly_price;
      } else {
        return this.selectedTier.price;
      }
    }
  }
};
</script>
