<template>
  <div class="mobile:w-full">
    <subscription-navbar
      :sub-step="subStep"
      @stepChange="handleStepChange"
      class="mobile:w-full"
    >
    </subscription-navbar>
    <div class="w-full flex-col flex">
      <div class="flex">
        <select-type v-if="subStep === 1"></select-type>
        <payment-options
          v-if="subStep === 2"
          :subscription-types="subscriptionTypes"
          :selected-type="selectedType"
          @chooseType="chooseType"
        ></payment-options>
        <overview
          v-if="subStep === 3"
          :selected-type="selectedType"
          :selected-tier="selectedTier"
          :promocode="discountInfo"
          @verifyPromoCode="verifyPromoCode"
        ></overview>
      </div>
      <div class="flex flex-row pt-4 w-6/12 mx-auto mobile:w-full">
        <button
          @click="prevPage()"
          v-if="subStep > 1"
          class="new-button-danger"
        >
          <span class="typcn typcn-chevron-left icon"></span>
          <span class="label">Eelmine</span>
        </button>
        <button
          @click="nextPage()"
          class="new-button-green ml-auto"
          v-if="subStep < 3"
        >
          <span class="pl-3 pr-2 label">Järgmine</span>
          <span class="typcn typcn-chevron-right icon"></span>
        </button>
        <button
          @click="createCompanySubscription()"
          class="new-button-green ml-auto"
          v-if="subStep === 3"
        >
          <span class="typcn typcn-tick icon"></span>
          <span class="label">Kinnita</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SubscriptionNavbar from "@/components/company/subscription/SubscriptionNavbar";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import RequestHandler from "@/assets/mixins/RequestHandler";
import SelectType from "./addsubscription/SelectType";
import PaymentOptions from "./addsubscription/PaymentOptions";
import Overview from "./addsubscription/Overview";

export default {
  name: "AddSubscription",
  components: {
    SubscriptionNavbar,
    SelectType,
    PaymentOptions,
    Overview
  },
  mixins: [RequestHandler],

  props: {
    subscriptionTiers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    subscriptionTypes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    companyUuid: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      selectedTier: null,
      selectedType: null,
      promocode: null,
      price: 0,
      total_price: 0,
      subStep: 1,
      discountInfo: {
        code: "",
        percentage: 0.0,
        valid: false,
        invalid: false
      }
    };
  },
  mounted() {
    this.selectedTier = this.subscriptionTiers[3];
    this.selectedType = this.subscriptionTypes[0];
  },
  methods: {
    createCompanySubscription() {
      this.$v.$touch();
      let subscriptionData = {
        company_id: this.companyUuid,
        type: this.selectedType.id,
        tier: this.selectedTier.id,
        selected_extras: []
      };
      if (
        this.discountInfo.code !== "" &&
        this.discountInfo.percentage !== 0.0
      ) {
        subscriptionData.discount_code = this.discountInfo.code;
      }
      this.$store.state.loading = true;

      this.apiRequest("subscription/new/", "post", true, subscriptionData).then(
        res => {
          if (res.status === 201) {
            this.$store.state.loading = false;
            window.location.href =
              process.env.VUE_APP_MONTONIO_URL + res.data.payment_token;
          }
        }
      );
    },
    verifyPromoCode(code) {
      this.discountInfo.invalid = false;
      this.apiRequest("subscription/verify/discount/", "post", true, {
        discount_code: code
      })
        .then(res => {
          if (res.status === 200) {
            this.discountInfo.code = code;
            this.discountInfo.percentage = res.data.discount_percentage;
            this.discountInfo.valid = true;
            this.discountInfo.invalid = false;
          }
        })
        .catch(error => {
          const errObj = { error };
          if (errObj.error.response.status === 404) {
            this.discountInfo.invalid = true;
          }
        });
    },
    handlePromoCodeActivation(e) {
      this.promocode = e;
    },
    chooseType(type) {
      this.selectedType = type;
    },
    chooseTier(tier) {
      if (this.selectedTier === tier) {
        this.selectedTier = null;
      } else {
        this.selectedTier = tier;
      }
    },
    handleStepChange(e) {
      this.subStep = e;
    },
    nextPage() {
      if (this.selectedTier.id === 1) {
        this.subStep = 3;
      } else {
        this.subStep += 1;
      }
      window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
    },
    prevPage() {
      if (this.subStep > 1) {
        this.subStep -= 1;
      }
      window.scrollTo({ top: 0, left: 0, behaviour: "smooth" });
    }
  },
  validations: {
    selectedType: required,
    selectedTier: required
  },
  computed: {
    parseStartDate() {
      return moment(this.subscriptionData.start_date).format(
        "MMMM Do YYYY, HH:mm:ss"
      );
    },
    parseEndDate() {
      return moment(this.subscriptionData.end_date).format(
        "MMMM Do YYYY, HH:mm:ss"
      );
    }
  }
};
</script>

<style lang="scss">
.landing-pricing-card {
  @apply w-1/3 flex flex-col rounded-md p-5 items-center flex-grow;
  .landing-pricing-card-recommend {
    @apply absolute bg-grey w-full text-center rounded-md text-white font-semibold shadow-lg text-xl;
    top: -3%;
  }
  .landing-pricing-row {
    @apply flex w-full mb-2 items-center;
    .landing-pricing-icon {
      @apply w-2/12 flex leading-none;
      .landing-pricing-tick {
        @apply text-green text-4xl border-2 rounded-md;
      }
      .landing-pricing-tick-white {
        @apply bg-white text-green text-4xl border-2 border-white rounded-md;
      }
      .landing-pricing-times-white {
        @apply bg-white text-danger text-4xl rounded-md border-2 border-white;
      }
      .landing-pricing-notick {
        @apply text-danger text-4xl border-2 rounded-md;
      }
    }
    .landing-pricing-text {
      @apply w-10/12 flex;
    }
  }
  .landing-pricing-price {
    @apply text-4xl font-extrabold;
  }
}
.landing-pricing-needs {
  @apply bg-grey w-full rounded-md py-5 px-3 flex flex-row;
  .landing-pricing-needs-header {
    @apply w-3/12 flex text-white items-center justify-center;
  }
  .landing-pricing-needs-container {
    @apply w-9/12 flex flex-row flex-wrap;
  }
}
@media (max-width: 992px) {
  .landing-pricing-needs {
    @apply flex-wrap;
    .landing-pricing-needs-header {
      @apply w-full text-center;
    }
    .landing-pricing-needs-container {
      @apply w-full;
    }
  }
}
</style>
