<template>
  <div
    class="flex w-1/2 medium:w-2/3 mx-auto justify-center items-center p-3 mobile:flex-col"
  >
    <div class="flex w-1/3 items-center justify-center mobile:mb-2">
      <div
        class="rounded-full flex items-center p-0 shadow duration-100"
        :class="[
          subStep === 1 ? 'bg-offwhite text-black' : '',
          subStep > 1 ? 'bg-darkgreen text-white' : ''
        ]"
        @click="subStep > 1 ? toggleStep(1) : ''"
      >
        <span
          class="p-2 rounded-full text-xl font-semibold text-white bg-green leading-none flex w-10 h-10 items-center justify-center"
          >1</span
        >
        <span
          class="pl-4 pr-6 font-medium"
          :class="subStep === 1 ? 'text-black' : 'text-white'"
          >Pakett</span
        >
      </div>
    </div>
    <div class="flex w-1/3 items-center justify-center mobile:mb-2">
      <div
        class="rounded-full flex items-center p-0 shadow duration-100"
        :class="[
          subStep === 2 ? 'text-black' : '',
          subStep > 2 ? 'bg-darkgreen' : 'bg-offwhite',
          subStep < 2 ? 'text-black' : ''
        ]"
        @click="subStep > 2 ? toggleStep(2) : ''"
      >
        <span
          :class="subStep < 2 ? 'bg-offerwhite text-black' : 'bg-green'"
          class="p-2 rounded-full text-xl font-semibold text-white bg-green leading-none flex w-10 h-10 items-center justify-center"
          >2</span
        >
        <span
          class="pl-4 pr-6 font-medium"
          :class="subStep > 2 ? 'text-white' : 'text-black'"
          >Tasumine</span
        >
      </div>
    </div>
    <div class="flex w-1/3 items-center justify-center">
      <div
        class="rounded-full flex items-center p-0 shadow duration-100"
        :class="[
          subStep === 3 ? 'text-black' : 'text-black',
          subStep > 3 ? 'bg-darkgreen' : 'bg-offwhite'
        ]"
        @click="toggleStep(3)"
      >
        <span
          :class="subStep < 3 ? 'bg-offerwhite text-black' : 'bg-green'"
          class="p-2 rounded-full text-xl font-semibold text-white bg-green leading-none flex w-10 h-10 items-center justify-center"
          >3</span
        >
        <span
          class="pl-4 pr-6 font-medium"
          :class="subStep === 3 ? 'text-black' : 'text-black'"
          >Kokkuvõte</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscriptionNavbar",
  props: {
    subStep: {
      type: Number,
      default: null
    }
  },
  methods: {
    toggleStep(stepToGoTo) {
      this.$emit("stepChange", stepToGoTo);
    }
  }
};
</script>

<style lang="scss">
.filled {
  @apply bg-green;
}
</style>
