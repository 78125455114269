<template>
  <div class="flex flex-col w-full">
    <div class="flex flex-col items-center w-full">
      <h2 class="mt-8 mb-4">Vali Gewodo Premium</h2>
      <span class="w-6/12 mb-8 text-center mobile:w-full"
        >Gewodo Premium on mõeldud ettevõttele, kes soovib hallata kogu oma
        ettevõtte tegevust Gewodos lihtsalt, mugavalt ja soodsalt.</span
      >
      <div class="flex flex-col w-6/12 mx-auto shadow rounded-xl mobile:w-full">
        <div class="flex justify-center mb-2">
          <span class="font-medium text-2xl">Gewodo Premium</span>
        </div>
        <div class="flex w-full text-sm px-4 py-4 mobile:flex-col">
          <div class="flex w-1/2 flex-col mobile:w-full">
            <div class="flex mb-2 items-center">
              <span
                class="typcn-tick typcn text-lg rounded-full flex items-center justify-center p-1 h-6 w-6 leading-none bg-green text-white mr-2"
              ></span>
              <span>Kõik, mis baaspaketis</span>
            </div>
            <div class="flex mb-2 items-center">
              <span
                class="typcn-tick typcn text-lg rounded-full flex items-center justify-center p-1 h-6 w-6 leading-none bg-green text-white mr-2"
              ></span>
              <span>Töötajate haldus (ülesanded, suhtlus)</span>
            </div>
            <div class="flex mb-2 items-center">
              <span
                class="typcn-tick typcn text-lg rounded-full flex items-center justify-center p-1 h-6 w-6 leading-none bg-green text-white mr-2"
              ></span>
              <span>Piiramatult töötajaid</span>
            </div>
            <div class="flex mb-2 items-center">
              <span
                class="typcn-tick typcn text-lg rounded-full flex items-center justify-center p-1 h-6 w-6 leading-none bg-green text-white mr-2"
              ></span>
              <span>Kliendiportaal</span>
            </div>
            <div class="flex mb-2 items-center">
              <span
                class="typcn-tick typcn text-lg rounded-full flex items-center justify-center p-1 h-6 w-6 leading-none bg-green text-white mr-2"
              ></span>
              <span>Arvete tegemine</span>
            </div>
            <div class="flex mb-2 items-center">
              <span
                class="typcn-tick typcn text-lg rounded-full flex items-center justify-center p-1 h-6 w-6 leading-none bg-green text-white mr-2"
              ></span>
              <span> Pakkumiste tegemine</span>
            </div>
            <div class="flex items-center mb-2">
              <span
                class="typcn-tick typcn text-lg rounded-full flex items-center justify-center p-1 h-6 w-6 leading-none bg-green text-white mr-2"
              ></span>
              <span>Täiendavad tööriistad iga kvartal</span>
            </div>
            <div class="flex items-center">
              <span
                class="typcn-tick typcn text-lg rounded-full flex items-center justify-center p-1 h-6 w-6 leading-none bg-green text-white mr-2"
              ></span>
              <span>10 GB salvestusruumi</span>
            </div>
          </div>

          <div
            class="flex w-1/2 flex-col justify-center items-center text-3xl font-medium mobile:w-full"
          >
            <span class="text-green">20€/kuu</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
