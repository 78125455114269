<template>
  <div
    class="flex flex-row py-3 w-6/12 mx-auto mobile:w-full mobile:items-center"
  >
    <div class="flex w-1/12 items-center justify-start mobile:w-2/12">
      <div class="flex flex-row items-center justify-center">
        <div
          @click="chooseType"
          class="h-10 w-10 mr-2 p-1 items-center flex cursor-pointer rounded-xl"
          :class="
            selectedType === subscriptionType
              ? 'bg-green'
              : 'border-green border-2'
          "
        >
          <span
            v-if="selectedType === subscriptionType"
            class="flex leading-none typcn typcn-tick text-white text-3xl"
          ></span>
        </div>
      </div>
    </div>
    <div class="flex w-11/12 mobile:flex-col mobile:w-10/12">
      <div class="flex flex-col w-9/12 mobile:w-full">
        <div
          class="flex flex-row mobile:flex-col items-center mobile:items-start"
        >
          <h4 class="mr-3">{{ subscriptionType.name }}</h4>
        </div>
        <div class="flex flex-row items-center text-sm">
          <span> {{ subscriptionType.information_text }}</span>
        </div>
      </div>
      <div
        class="w-3/12 mobile:w-full mobile:mx-auto flex items-center text-lg font-medium bg-offwhite rounded-xl shadow justify-center"
      >
        {{ subscriptionType.price }}€/kuus
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscriptionType",
  props: {
    subscriptionType: null,
    selectedType: null
  },
  methods: {
    chooseType() {
      this.$emit("chooseType", this.subscriptionType);
    }
  }
};
</script>
