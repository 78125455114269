<template>
  <div class="flex flex-col w-full items-center mb-4">
    <h2 class="my-8">Vali paketi pikkus ja maksegraafik</h2>
    <div
      class="flex rounded-full shadow w-auto font-medium duration-100 mb-4"
      :class="tabHovered ? 'bg-darkgreen' : ''"
    >
      <span
        class="rounded-full px-4 py-2 duration-100"
        :class="
          billingTab === 0
            ? 'bg-green text-white'
            : 'hover:bg-darkgreen hover:text-white cursor-pointer'
        "
        @mouseenter="billingTab === 1 ? (tabHovered = true) : ''"
        @mouseleave="tabHovered = false"
        @click="changeTab(0)"
        >Aasta</span
      >
      <span
        class="rounded-full px-4 py-2 duration-100"
        :class="
          billingTab === 1
            ? 'bg-green text-white'
            : 'hover:bg-darkgreen hover:text-white cursor-pointer'
        "
        @mouseenter="billingTab === 0 ? (tabHovered = true) : ''"
        @mouseleave="tabHovered = false"
        @click="changeTab(1)"
        >3 kuud</span
      >
    </div>
    <subscription-type
      v-for="item in subscriptionTypes.filter(x => x.type === typeToRender)"
      :key="item.id"
      :subscription-type="item"
      :selected-type="selectedType"
      @chooseType="handleChosenType"
    ></subscription-type>
  </div>
</template>

<script>
import SubscriptionType from "./SubscriptionType";
export default {
  components: {
    SubscriptionType
  },
  data() {
    return {
      billingTab: 0,
      tabHovered: false
    };
  },
  props: {
    subscriptionTypes: Array,
    selectedType: Object
  },
  mounted() {
    if ([8, 9].includes(this.selectedType.id)) {
      this.billingTab = 1;
    }
  },
  methods: {
    handleChosenType(e) {
      this.$emit("chooseType", e);
    },
    changeTab(tab) {
      this.billingTab = tab;
      this.tabHovered = false;
    }
  },
  computed: {
    typeToRender() {
      if (this.billingTab === 0) {
        return "yearly";
      } else {
        return "monthly";
      }
    }
  }
};
</script>
